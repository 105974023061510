import React, { useContext, useEffect, useState } from 'react'
import { axiosBase } from '../Rootlayout';
import { SherpaContext } from '../context/notes/Sherpacontext';
import TopBanner from './TopBanner';
import { Parser } from 'html-to-react';

export default function Porters() {
    const { banner } = useContext(SherpaContext);
    const [porters, setPorters] = useState([]);
    useEffect(() => {
        //for cookies
        axiosBase.get(`/page/porters`)
            .then(response => response.data)
            .then((response) => setPorters(response.data))
            .catch(err => {
                console.log(err.message);
            })

    }, []);
    return (
        <div>
            <TopBanner topBannerTitle={porters.title} topBannerBottom={banner.banner_bottom_img} TopBannerBg={JSON.stringify(banner.contact_img)} />
            <div className="container">
                <div className="row p-3 p-lg-5">
                    <div className="col-12">
                        {Parser().parse(porters.description)}
                    </div>
                </div>
                {/* <div className="row p-3 p-md-5">
                    <div className="card mb-3 px-0">
                        <div className="row g-0">
                            <div className="col-md-5">
                                <img src="./assets/img/user.png" className="img-fluid rounded-start" alt="..." style={{ minHeight: '300px' }} />
                            </div>
                            <div className="col-md-7">
                                <div className="card-body">
                                    <h5 className="card-title">Annapurna Trek</h5>
                                    <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-3 px-0">
                        <div className="row g-0">
                            <div className="col-md-5">
                                <img src="./assets/img/user.png" className="img-fluid rounded-start" alt="..." style={{ minHeight: '300px' }} />
                            </div>
                            <div className="col-md-7">
                                <div className="card-body">
                                    <h5 className="card-title">Everest Trek</h5>
                                    <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-3 px-0">
                        <div className="row g-0">
                            <div className="col-md-5">
                                <img src="./assets/img/user.png" className="img-fluid rounded-start" alt="..." style={{ minHeight: '300px' }} />
                            </div>
                            <div className="col-md-7">
                                <div className="card-body">
                                    <h5 className="card-title">Langtang Trek</h5>
                                    <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-3 px-0">
                        <div className="row g-0">
                            <div className="col-md-5">
                                <img src="./assets/img/user.png" className="img-fluid rounded-start" alt="..." style={{ minHeight: '300px' }} />
                            </div>
                            <div className="col-md-7">
                                <div className="card-body">
                                    <h5 className="card-title">Chure Trek</h5>
                                    <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}
