

import React, { useState } from 'react'

import Modal from 'react-modal';

export default function TestimonialItem(props) {
    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginTop: '3%',
            transform: 'translate(-50%, -50%)',
        },
    };
    return (
        <div className="col p-md-3 px-xxl-4">
            <div className='shadow rounded-3 bg-white position-relative' >
                {/* <p className='textcolor-5 d-flex border-bottom'>
                    {Array.from({ length: props.rate }, (_, i) => <i key={i} className="bi bi-star-fill"></i>)}
                </p> */}
                {/* {props.testimonialMsg === null ? <p><iframe src={`${props.link}`} width="100%" height="200" allowFullScreen="allowfullscreen" title="location"></iframe></p> : ''} */}
                {props.link === null ? <img src={props.image} alt="" style={{ width: '100%' }} /> : <div>
                    <div onClick={openModal}><img src={`https://img.youtube.com/vi/${props.link}/0.jpg`} alt="" className='w-100' /></div>
                </div>}
                {/* {props.link != null || props.image != null ? <div className="position-absolute h-100 w-100 top-0 start-0" style={{ background: 'rgb(33 37 41 / 40%)' }}>
                    <Link to={`https://www.youtube.com/watch?v=${props.link}`} target="_blank" rel="noopener noreferrer" className="d-flex align-items-end justify-content-end h-100 w-100 pe-3 pb-3">
                        <i className="bi bi-youtube fs-1" style={{ color: '#cd201f', opacity: '.5' }}></i>
                    </Link>
                </div> : ''} */}
                {props.link != null || props.image != null ? <div className="position-absolute  bottom-0 end-0">
                    <div className="d-flex align-items-end justify-content-end pe-3 pb-3" >
                        <div ><i className="bi bi-youtube fs-1" style={{ color: '#cd201f', opacity: '.5' }}></i></div>
                    </div>

                </div> : ''}
                {props.link === null && props.image === null ? <span className='p-3'>{props.testimonialMsg}</span> : ''}
            </div>

            <div className='p-3 pb-0 text-center'>
                <h4>{props.testimonialName}</h4>
                <h6>{props.testimonialOccupation}</h6>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <button className="btn-close fs-2 text-end" onClick={closeModal}></button>
                {/* <button type="button"  data-bs-dismiss="modal" aria-label="Close"></button> */}
                <div>
                    <iframe width="560" height="315" src={`https://www.youtube.com/embed/${props.link}`} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
            </Modal>



        </div>
    )
}
