import { Parser } from 'html-to-react'
import React from 'react'

export default function AboutTeamItem(props) {
    return (
        <div className="p-3">
            <button className="col about-team-item border-0" data-bs-toggle="modal" data-bs-target={`#exampleModal${props.id}`}>
                <img src={props.teamImg} alt="" />
                <div className="about-team-item-text">
                    <h5>{props.teamName}</h5>
                    {props.teamPosition && <p>{props.teamPosition}</p>}
                </div>
            </button>
            <div className="modal fade" id={`exampleModal${props.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-fullscreen-sm-down" >
                    <div className="modal-content">
                        <div className="modal-header pb-0 border-0">
                            <h1 className="modal-title pb-0 fw-semibold" id="exampleModalLabel">{props.teamName}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-header my-0 py-0">
                            {props.teamPosition && <p>{props.teamPosition}</p>}
                        </div>

                        <div className="modal-body">
                            <div className="row team-main-1  p-3">
                                <div className="col-md-3">
                                    <img src={props.teamImg} className="w-100 rounded-3 " alt="..." />
                                </div>
                                <div className="col-md-9">
                                    <>{Parser().parse(props.teamDesc)}</>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
