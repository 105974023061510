import React from "react";
import Slider from "react-slick";
import TestimonialItem from "./TestimonialItem";

export default function TestimonialSlider(props) {
  //test data 
  // const link = "e0hVp1iNZSs";
  // const image = "https://picsum.photos/200/300";
  // const content = "this is dummy content"
  var settings1 = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    swipeToSlide: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ],
  };
  return (
    <div className="testimonial-slider p-3 p-xl-5 g-0">
      <div className="row g-0 p-3">
        <div className="col mb-3 mb-xl-5">
          <Slider {...settings1}>
            {props.testimonialsData.map((item, i) => (
              <TestimonialItem
                key={item.name}
                i={i}
                testimonialMsg={item.content}
                testimonialName={item.name}
                testimonialOccupation={item.postion}
                image={item.image}
                link={item.video_link}
              />

            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
