import React, { useEffect, useState } from 'react'
import Banner from './Banner'
import Hero from './Hero'
import Philosophy from './Philosophy'
import Packages from './Packages'
import Why from './Why'
import Explore from './Explore'
import Testimonials from './Testimonials'
import LatestTrip from './LatestTrip'
import Spinner from './Spinner'
import { axiosBase } from "../Rootlayout";

export default function Home() {
    const [homedata, setHomeData] = useState([]);
    const [trekks, setTrekks] = useState([]);
    const [peaks, setPeaks] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [error, setError] = useState('');
    useEffect(() => {
        axiosBase.get(`home`, { onDownloadProgress: setLoading(true) })
            .then(response => response.data)
            .then(response => {
                setHomeData(response.data);
                // setLoading(false);
                setTimeout(() => {
                    setLoading(false);;
                }, 1000);
            })
            .catch(err => {
                console.log(err.message);
            });
        axiosBase.get(`trekks`)
            .then(response => response.data)
            .then((response) => setTrekks(response.data))
            .catch(err => {
                console.log(err.message);
            })

        axiosBase.get(`peaks`)
            .then(response => response.data)
            .then((response) => setPeaks(response.data))
            .catch(err => {
                console.log(err.message);
            })

    }, []);
    // console.log(homedata.reviews)
    return (
        <main className='home'>
            <div className="container-fluid g-0">
                {loading ? <Spinner /> : ''}
                <Hero heroTitleTop={homedata.banner_title} heroTitleLeft={homedata.banner_sub_title} heroTitleRight={homedata.banner_sub_title_2} heroExploreLink="/about-us" heroBtnText="Explore Now" heroBg={homedata.banner_img} />
                <Philosophy philLeft={homedata.philosophy_img} philRightTitleText={homedata.pholosophy_title} heading={homedata.philosophy_heading} philBody={homedata.Philosophy_body} philBtn="Get In Touch" philBtnLink="/contact-us" />
                <Testimonials testimonialTitle="Adventure Talk" />
                <Packages packagesTop="Life time package" packagesHeading="trekking packages" packagesData={trekks} bannerTop="assets/img/main-inner-bottom-bg.png" />
                <Why whyImg={homedata.why_img} whyMoreLink="/about-us#why-more" whyContent={homedata.why_content} />
                <Packages packagesTop="Adventure and fun" packagesHeading="Peak climbing packages" packagesData={peaks} />
                <Explore exploreLink="/about-us" exploreTop={homedata.explore_heading} exploreBottom={homedata.explore_sub_heading} />
                <img src="assets/img/birds.png" alt="" className='w-100' />
                <img src="assets/img/testimonials-below.png" alt="" className='w-100' />
                {/* LatestTrip LatestTripLink="#" LatestTripImg="assets/img/langtang.jpg" LatestTripTitle="Amadhablan Peak expedition" rate={3} LatestTripMsg="Integer vulputate a quam id gravida." LatestTripMsgName="Neo linx Pvt Ltd." /> */}
                {/* {homedata.reviews?.map((item) => (
                    <LatestTrip key={item.id} LatestTripLink={homedata.youtube_video_link} LatestTripImg={homedata.youtube_video_img} LatestTripTitle={item.post_title} rate={item.rating} LatestTripMsg={item.content} LatestTripMsgName={item.name} />
                ))} */}

                {homedata?.reviews != null ? <LatestTrip review={homedata.reviews} LatestTripLink={homedata.youtube_video_link} LatestTripImg={homedata.youtube_video_img} /> : ''}


                <Banner BannerLink="#" BannerBg="/assets/img/banner-bg.png" />
            </div>
        </main>
    )
}
