import React, { useEffect, useState } from 'react'
import TopBanner from './TopBanner'
import PackageItem from './PackageItem'
import Banner from './Banner'
// import { SherpaContext } from '../context/notes/Sherpacontext';
import { axiosBase } from '../Rootlayout';

export default function PeakClimbing() {
    // const { peakclimbing, setPeakClimbing } = useContext(SherpaContext);
    const [peakclimbing, setPeakClimbing] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const Data = peakclimbing.peaks;
    const recordPerPage = 9;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const records = Data?.slice(firstIndex, lastIndex);
    const npage = Math.ceil(Data?.length / recordPerPage);
    var numbers = [];
    for (var i = 1; i <= npage; i++) {
        numbers.push(i);
    }
    useEffect(() => {
        axiosBase.get(`peakclimbing`)
            .then(response => response.data)
            .then((response) => setPeakClimbing(response.data))
            .catch(err => {
                console.log(err.message);
            })

    }, []);
    return (
        <div className="container-fluid g-0">
            <TopBanner topBannerTitle={peakclimbing.peakclimbing} topBannerBottom={peakclimbing.banner_bootom_colour_img} TopBannerBg={JSON.stringify(peakclimbing.banner_img)} />
            <div className="row g-0 packages">
                {records?.map((item, index) => (
                    <div className="col-12 col-sm-6 col-lg-4" key={index}>
                        <PackageItem packageName={item.title} packageItemImg={item.featured_img} iconLeftText={item.max_elevation} iconRIghtText={item.trip_length} btnText="find out more" btnLink={`/details/${item.slug}`} />
                    </div>

                ))}
                <nav aria-label='Page navigation example' className={`${npage > 1 ? '' : 'd-none'}`}>
                    <ul className='pagination justify-content-center'>
                        <li className={`page-item ${npage !== null ? '' : 'd-none'} `}>
                            <button
                                className={`page-link ${currentPage === 1 ? "disabled" : ""}`}
                                onClick={prePage}
                            >
                                Previous
                            </button>
                        </li>
                        {numbers.map((n, i) => (
                            <li
                                className={`page-item ${currentPage === n ? "active" : ""}`}
                                key={i}
                            >
                                <button className='page-link' onClick={() => changeCPage(n)}>
                                    {n}
                                </button>
                            </li>
                        ))}
                        <li className={`page-item ${npage !== null ? '' : 'd-none'} `}>
                            <button
                                className={`page-link ${currentPage === npage ? "disabled" : ""}`}
                                onClick={nextPage}
                            >
                                Next
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
            <Banner BannerLink="#" BannerBg="/assets/img/banner-bg-trek.png" />
        </div>
    )
    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1);
        }
    }
    function changeCPage(id) {
        setCurrentPage(id);
    }
}
