import React, { useContext, useEffect, useState } from "react";
import TopBanner from "./TopBanner";
import Banner from "./Banner";
import ClientReview from "./ClientReview";
import { Link, useParams } from "react-router-dom";
import { axiosBase, recaptchaSiteKey } from "../Rootlayout";
import { Parser } from "html-to-react";
import MainInnerPageLeft from "./MainInnerPageLeft";
import ReCAPTCHA from "react-google-recaptcha";
import { SherpaContext } from "../context/notes/Sherpacontext";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
export default function MainInnerPage() {
  var { slug, setSlug } = useParams();
  const [pagedata, setPageData] = useState("");
  const [countries, setCountries] = useState("");
  const { setCountryData } = useContext(SherpaContext);
  // const [onChange] = useState('');
  // const [error, setError] = useState('');
  useEffect(() => {
    axiosBase
      .get(`./details/${slug}`)
      .then((response) => response.data)
      .then((response) => setPageData(response.data))
      .then((response) => setCountryData(response.data))
      .catch((err) => {
        console.log(err.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    axiosBase
      .get("countries")
      .then((response) => response.data)
      .then((response) => setCountries(response.data))
      .catch((err) => {
        console.log(err.message);
      });
  }, []); // eslint-disable-next-line react-hooks/exhaustive-deps
  const [name, createName] = useState("");
  const [email, createEmail] = useState("");
  const [phone, createPhone] = useState("");
  const [country, createCountry] = useState("");
  const [no_of_adults, createAdult] = useState("");
  const [no_of_childs, createChildren] = useState("");
  const [message, createComments] = useState("");

  const [verified, setVerified] = useState("");
  function onChange(value) {
    console.log("Captcha value:", value);
    setVerified(true);
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    const post = {
      name: name,
      email: email,
      phone: phone,
      country: country,
      no_of_adults: no_of_adults,
      no_of_childs: no_of_childs,
      message: message,
    };
    try {
      const res = await axiosBase.post(`abouttrips/${pagedata.id}`, post);
      alert(res.data.message);
    } catch (e) {
      alert(e);
    }
  };

  const [expandedAccordions, setExpandedAccordions] = useState([]);

  const accordionClicked = (index) => {
    if (expandedAccordions.includes(index))
      setExpandedAccordions(
        expandedAccordions.filter((number) => number !== index)
      );
    else setExpandedAccordions([...expandedAccordions, index]);
  };

  const collapseAll = () => {
    setExpandedAccordions([]);
  };

  const expandAll = () => {
    const newArray = [];
    pagedata.itinerary?.map((item, index) => newArray.push(index));
    setExpandedAccordions(newArray);
  };
  const print = () => {
    let printContents = document.getElementById("printThis").innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    // window.open(`${url}details/${id}`);
    window.location.reload();
  };

  return (
    <div className="main-inner-page" id="mip">
      <div className="container-fluid px-0">
        <TopBanner
          topBannerTitle={pagedata.title}
          topBannerBottom={pagedata.banner_bottom_img}
          TopBannerBg={JSON.stringify(pagedata.banner_image)}
        />
        <div className="container">
          <div className="row px-2 p-md-5 pb-md-3 flex-lg-row-reverse">
            <div className="col-12 main-inner-page-top">
              <div className="row p-3 p-md-auto g-0 pb-2 pb-lg-0 border-bottom">
                {pagedata.start_at == null ? (
                  ""
                ) : (
                  <div className="col-6 col-md-4 col-lg-3 main-inner-page-top-item d-flex justify-content-lg-center justify-content-start align-items-center">
                    <div className="main-inner-page-top-item-img">
                      <img src="/assets/img/start.png" alt="" />
                    </div>
                    <div className="px-1 px-lg-3">
                      <strong>Starts at:</strong>
                      <br></br>
                      <span>{pagedata.start_at}</span>
                    </div>
                  </div>
                )}
                {pagedata.ends_at == null ? (
                  ""
                ) : (
                  <div className="col-6 col-md-4 col-lg-3 main-inner-page-top-item d-flex justify-content-lg-center justify-content-start align-items-center">
                    <div className="main-inner-page-top-item-img">
                      <img src="/assets/img/end.png" alt="" />
                    </div>
                    <div className="px-1 px-lg-3">
                      <strong>End at:</strong>
                      <br></br>
                      <span>{pagedata.ends_at}</span>
                    </div>
                  </div>
                )}
                {pagedata.trek_region == null ? (
                  ""
                ) : (
                  <div className="col-6 col-md-4 col-lg-3 main-inner-page-top-item d-flex justify-content-lg-center justify-content-start align-items-center">
                    <div className="main-inner-page-top-item-img">
                      <img src="/assets/img/region.png" alt="" />
                    </div>
                    <div className="px-1 px-lg-3">
                      <strong>Trek Region:</strong>
                      <br></br>
                      <span>{pagedata.trek_region}</span>
                    </div>
                  </div>
                )}
                {pagedata.transport == null ? (
                  ""
                ) : (
                  <div className="col-6 col-md-4 col-lg-3 main-inner-page-top-item d-flex justify-content-lg-center justify-content-start align-items-center">
                    <div className="main-inner-page-top-item-img">
                      <img src="/assets/img/transport.png" alt="" />
                    </div>
                    <div className="px-1 px-lg-3">
                      <strong>Transport:</strong>
                      <br></br>
                      <span>{pagedata.transport}</span>
                    </div>
                  </div>
                )}
                {pagedata.difficulty_level == null ? (
                  ""
                ) : (
                  <div className="col-6 col-md-4 col-lg-3 main-inner-page-top-item d-flex justify-content-lg-center justify-content-start align-items-center">
                    <div className="main-inner-page-top-item-img">
                      <img src="/assets/img/rock-climbing.png" alt="" />
                    </div>
                    <div className="px-1 px-lg-3">
                      <strong>Difficulty Level:</strong>
                      <br></br>
                      <span>{pagedata.difficulty_level}</span>
                    </div>
                  </div>
                )}
                {pagedata.max_elevation == null ? (
                  ""
                ) : (
                  <div className="col-6 col-md-4 col-lg-3 main-inner-page-top-item d-flex justify-content-lg-center justify-content-start align-items-center">
                    <div className="main-inner-page-top-item-img">
                      <img src="/assets/img/elevation.png" alt="" />
                    </div>
                    <div className="px-1 px-lg-3">
                      <strong>Max. Elevation:</strong>
                      <br></br>
                      <span>{pagedata.max_elevation}</span>
                    </div>
                  </div>
                )}
                {pagedata.comfort == null ? (
                  ""
                ) : (
                  <div className="col-6 col-md-4 col-lg-3 main-inner-page-top-item d-flex justify-content-lg-center justify-content-start align-items-center">
                    <div className="main-inner-page-top-item-img">
                      <img src="/assets/img/comfort.png" alt="" />
                    </div>
                    <div className="px-1 px-lg-3">
                      <strong>Comfort:</strong>
                      <br></br>
                      <span>{pagedata.comfort}</span>
                    </div>
                  </div>
                )}
                {pagedata.trip_length == null ? (
                  ""
                ) : (
                  <div className="col-6 col-md-4 col-lg-3 main-inner-page-top-item d-flex justify-content-lg-center justify-content-start align-items-center">
                    <div className="main-inner-page-top-item-img">
                      <img src="/assets/img/trip.png" alt="" />
                    </div>
                    <div className="px-1 px-lg-3">
                      <strong>Trip Length:</strong>
                      <br></br>
                      <span>{pagedata.trip_length}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 d-none d-lg-block col-lg-6 col-xl-4 main-inner-page-right">
              <div className="main-inner-page-right-inner">
                <h6>Got any questions about this trip</h6>
                <p>
                  Package Name:<span>{pagedata.title}</span>
                </p>
                <form onSubmit={onSubmit}>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Name"
                      required
                      onChange={(event) => {
                        createName(event.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Email"
                      required
                      onChange={(event) => {
                        createEmail(event.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="tel"
                      className="form-control"
                      name="pnumber"
                      placeholder="Phone"
                      required
                      onChange={(event) => {
                        createPhone(event.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      required
                      onChange={(event) => {
                        createCountry(event.target.value);
                      }}
                    >
                      <option defaultValue>Choose Country</option>
                      {countries.countries?.map((item, i) => (
                        <option key={i} value={`${item.country_name}`}>
                          {item.country_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <input
                      type="number"
                      min="1"
                      max="5"
                      className="form-control"
                      name="adult"
                      placeholder="Number of Adult"
                      required
                      onChange={(event) => {
                        createAdult(event.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="number"
                      min="0"
                      max="5"
                      className="form-control"
                      name="children"
                      placeholder="Number of Children"
                      required
                      onChange={(event) => {
                        createChildren(event.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <textarea
                      className="form-control"
                      placeholder="Message"
                      name="message"
                      style={{ height: "100px" }}
                      required
                      onChange={(event) => {
                        createComments(event.target.value);
                      }}
                    ></textarea>
                  </div>
                  <div className="text-center">
                    <div
                      style={{
                        transform: "scale(0.9)",
                        transformOrigin: "0 0",
                      }}
                    >
                      <ReCAPTCHA
                        sitekey={recaptchaSiteKey}
                        onChange={onChange}
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn button-primary-outline mt-3"
                      disabled={!verified}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-8 main-inner-page-left">
              <MainInnerPageLeft images={pagedata.images} />
            </div>
          </div>
        </div>
        <div className="main-inner-page-bottom bg-light">
          <div className="main-inner-page-bottom-img-top">
            <div className="d-flex">
              <img src="/assets/img/main-inner-bottom-bg.png" alt="" />
            </div>
          </div>
          <div className="container">
            <div className="row flex-lg-row-reverse py-3 justify-content-center">
              {pagedata.map_image ? <div className="col-12 col-sm-6 col-xl-4 mb-3">
                <img src={pagedata.map_image} alt="" className="w-100" />
              </div> : ''}
              <div className="col-12 col-lg-6 col-xl-8">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link px-2 py-3 px-md-3 p-xxl-4 active"
                      id="overview-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#overview-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="overview-tab-pane"
                      aria-selected="true"
                    >
                      Overview
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link px-2 px-md-3 py-3 p-xxl-4"
                      id="itinerary-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#itinerary-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="itinerary-tab-pane"
                      aria-selected="false"
                    >
                      Itinerary
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link px-2 px-md-3 py-3 p-xxl-4"
                      id="whatsincluded-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#whatsincluded-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="whatsincluded-tab-pane"
                      aria-selected="false"
                    >
                      Whats Included
                    </button>
                  </li>
                </ul>
                <div className="tab-content p-3 py-md-5">
                  <div
                    className="tab-pane fade show active"
                    id="overview-tab-pane"
                    role="tabpanel"
                    aria-labelledby="overview-tab"
                    tabIndex="0"
                  >
                    {Parser().parse(pagedata.overview)}
                  </div>
                  <div
                    className="tab-pane fade "
                    id="itinerary-tab-pane"
                    role="tabpanel"
                    aria-labelledby="itinerary-tab"
                    tabIndex="0"
                  >
                    <div className="buttons-collapse-expand text-end mb-3">

                      <button type="button" className="btn btn-sm btn-primary me-3" onClick={expandAll}>
                        Expand All
                      </button>
                      <button type="button" className="btn btn-sm btn-danger" onClick={collapseAll}>
                        Collapse All
                      </button>
                    </div>

                    <div className="accordion" id="accordionExample">
                      {pagedata.itinerary?.map((item, index) => (
                        <Accordion
                          key={index}
                          onChange={() => accordionClicked(index)}
                          expanded={expandedAccordions.includes(index)}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">
                              <span className="textcolor-5">
                                {item.title}
                              </span> {item.content}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>{item.full_content}</Typography>
                          </AccordionDetails>
                        </Accordion>
                        // <p key={index}>
                        //   <span>{item.title}</span>
                        //   <br></br>
                        //   {item.content}
                        // </p>
                      ))}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="whatsincluded-tab-pane"
                    role="tabpanel"
                    aria-labelledby="whatsincluded-tab"
                    tabIndex="0"
                  >
                    {Parser().parse(pagedata.whats_included)}
                  </div>
                </div>
              </div>
              <div id="printThis" style={{ display: "none" }}>
                OverView
                <p>{pagedata.overview}</p>
                <br></br>
                {pagedata.itinerary?.map((item, index) => (
                  <p key={index}>
                    <span>{item.title}</span>
                    <br></br>
                    {item.content}
                  </p>
                ))}
                <br></br>
                Whats Included
                <p>{pagedata.whats_included}</p>
              </div>
              <div className="col-12 d-flex">
                <button
                  className=" btn d-flex align-items-center"
                  onClick={print}
                  formTarget="_blank"
                >
                  <i className="bi bi-printer"></i>
                  <p className="px-2 my-0">Printable Form</p>
                </button>
                <Link
                  className=" btn d-flex align-items-center"
                  to="/contact-us"
                >
                  <i className="bi bi-printer"></i>
                  <p className="px-2 my-0">Contact Us</p>
                </Link>
              </div>
            </div>
          </div>
          <div className="main-inner-page-bottom-img-bottom">
            <div className="d-flex">
              <img src="/assets/img/main-inner-bottom-bg.png" alt="" />
            </div>
          </div>
        </div>
        <ClientReview
          reviews={pagedata.reviews}
          slug={slug}
          id={pagedata.id}
          countries={countries}
        />
        <div className="row p-3 d-lg-none m-0">
          <div className="col-12 col-lg-6 col-xl-4 main-inner-page-right">
            <div className="main-inner-page-right-inner">
              <h6>Got any questions about this trip</h6>
              <p>
                Package Name:<span>{pagedata.title}</span>
              </p>
              <form onSubmit={onSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="Name"
                    required
                    onChange={(event) => {
                      createName(event.target.value);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="Email"
                    required
                    onChange={(event) => {
                      createEmail(event.target.value);
                    }}
                  />
                </div>
                <div className="mb-3 d-flex">
                  <input
                    type="tel"
                    className="form-control me-3"
                    name="pnumber"
                    placeholder="Phone"
                    required
                    onChange={(event) => {
                      createPhone(event.target.value);
                    }}
                  />
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    required
                    onChange={(event) => {
                      createCountry(event.target.value);
                    }}
                  >
                    <option defaultValue>Country</option>
                    {countries.countries?.map((item, i) => (
                      <option key={i} value={`${item.country_name}`}>
                        {item.country_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3 d-flex">
                  <input
                    type="number"
                    min="1"
                    max="5"
                    className="form-control me-2"
                    name="adult"
                    placeholder="Adults"
                    required
                    onChange={(event) => {
                      createAdult(event.target.value);
                    }}
                  />
                  <input
                    type="number"
                    min="0"
                    max="5"
                    className="form-control"
                    name="children"
                    placeholder="Childrens"
                    required
                    onChange={(event) => {
                      createChildren(event.target.value);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Message"
                    name="message"
                    style={{ height: "100px" }}
                    required
                    onChange={(event) => {
                      createComments(event.target.value);
                    }}
                  ></textarea>
                </div>
                <div className="text-center">
                  <div
                    style={{
                      transform: "scale(0.9)",
                      transformOrigin: "0 0",
                    }}
                  >
                    <ReCAPTCHA
                      sitekey={recaptchaSiteKey}
                      onChange={onChange}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn button-primary-outline mt-3"
                    disabled={!verified}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <ClientReview reviews={pagedata.reviews} id={pagedata.id} rating={pagedata.rating} name={pagedata.name} content={pagedata.content} /> */}
        <div className="related-trip">
          <div className="related-trip-top">
            <div className="d-flex">
              <img src="/assets/img/main-inner-bottom-bg.png" alt="" />
            </div>
          </div>
          <div className="container">
            <div className="row p-3 py-md-5 g-0">
              <div className="col-12">
                <p className="textcolor-primary text-capitalize text-center">
                  you may also like
                </p>
                <h3>related trip</h3>
                <div className="row related-trip-img">
                  {/* <MainInnerRelated dataRelated={pagedata.recent} /> */}
                  {pagedata.recent?.map((item, index) => (
                    <div
                      className="col-12 col-md-6 col-lg-4 related-trip-img-item text-center p-3"
                      key={index}
                    >
                      <img src={item.featured_img} alt="" />
                      <h5>{item.title}</h5>
                      <h4>{item.trip_length}</h4>
                      <Link
                        to={`/details/${item.slug}`}
                        onClick={() => setSlug(slug)}
                        className="text-decoration-none textcolor-secondary text-uppercase"
                      >
                        View details
                      </Link>
                      {/* <a href={`${url}details/${item.slug}`} className="text-decoration-none textcolor-secondary text-uppercase small">View details</a> */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Banner BannerLink="#" BannerBg="/assets/img/banner-bg-trek.png" />
      </div>
    </div>
  );
}
