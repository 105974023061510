import React from 'react'

export default function GoogleMap() {
    return (
        <div className='row g-0 position-relative'>
            <div className="position-absolute top-0">
                <div className="d-flex align-items-start">
                    <img src="assets/img/main-inner-bottom-bg.png" alt="" className='w-100' />
                </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56516.317136200545!2d85.28493291000449!3d27.708954252174927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1691489640199!5m2!1sen!2snp" width="600" height="450" title="location" className='border-0 w-100' allowFullScreen="" loading="lazy" referrerFolicy="no-referrer-when-downgrade"></iframe>
            <div className="position-absolute" style={{ bottom: '-12px' }}>
                <div className="d-flex align-items-end">
                    <img src="assets/img/main-inner-bottom-bg.png" alt="" style={{ transform: 'rotateX(180deg) rotateY(180deg)' }} className='w-100' />
                </div>
            </div>
        </div>
    )
}
