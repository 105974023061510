import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import TopBanner from "./TopBanner";
import Banner from "./Banner";
import Fancybox from "./Fancybox";
import { axiosBase } from "../Rootlayout";

export default function GalleryListing(props) {
  const { id } = useParams();
  // const id = localStorage.getItem('galleriesIdFromLocalStorage');
  // const all = localStorage.getItem('galleriesAllFromLocalStorage')
  // const { galleries } = useContext(SherpaContext);
  // const navigate = useNavigate();
  // const [imagegalleries, setImageGalleries] = useState([]);
  const [propsData, setPropsData] = useState([]);
  // const location = useLocation();
  // setPropsData(location.state)

  useEffect(() => {
    axiosBase
      .get(`/imagegalleries/${id}`)
      .then((response) => response.data)
      .then((response) => setPropsData(response.data))
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <>
      <TopBanner
        topBannerTitle={propsData.title}
        topBannerBottom="/assets/img/top-banner-bottom.png"
        TopBannerBg={propsData.coverImages}
      />
      <div className="container">
        <div className="gallery-listing row my-5 justify-content-center">
          <Fancybox
            options={{
              Carousel: {
                infinite: false,
              },
            }}
            className="text-center"
          >
            {propsData.galleryImages?.map((item, i) => (
              <Link
                data-fancybox="gallery"
                to={item}
                key={i}
                className="text-center"
              >
                <img src={item} alt="" className="p-lg-2 p-1" />
              </Link>
            ))}
          </Fancybox>
        </div>
      </div>
      <Banner BannerLink="#" BannerBg="/assets/img/banner-bg-white.png" />
    </>
  );
}

// if (propsData == null) {
//     axiosBase.get(`./imagegalleries/${id}`)
//         .then(response => response.data)
//         .then((response) => setImageGalleries(response.data))
//         .catch(err => {
//             console.log(err.message);
//         })
//     setPropsData(imagegalleries);
// }

// const getData = async (e) => {
//     e.preventDefault()

//     try {

//         axiosBase.get(`imagegalleries`)
//             .then(response => response.data)
//             .then((response) => setImageGalleries(response.data))
//             .catch(err => {
//                 console.log(err.message);
//             });

//     } catch (e) {
//         alert(e)
//     }
// }

// useEffect(() => {
//     getData();

// }, []);
// if (propsData == null) {
//     // window.href('/galleries');
//     // window.location.href = (-4);
//     // window.location.reload();
// }
// useEffect(() => {
//     if (props.Data !== null) {
//         var propsData = all;
//         setPropsData(location.state)
//     } else {
//         setPropsData(all)
//     }

// }, []);
// if (propsData == null) {
//     navigate(-1);
//     // var propsData = imagegalleriesContext;
//     // axiosBase.get(`imagegalleries`)
//     //     .then(response => response.data)
//     //     .then((response) => setImageGalleries(response.data))
//     //     .catch(err => {
//     //         console.log(err.message);
//     //     });
//     // if (imagegalleries !== null) {
//     //     console.log(imagegalleries);
//     //     console.log(imagegalleries[0]);
//     // }
// }
// console.log(id)

// const goback = async (e) => {
//     window.reload()
// }
