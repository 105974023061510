import React, { useContext, useEffect, useState } from 'react'
import { axiosBase } from "../Rootlayout";
import { SherpaContext } from '../context/notes/Sherpacontext';
import { Link } from 'react-router-dom';


export default function Footer() {
    const [aboutus, setAboutus] = useState([]);
    const [explorelinks, setExploreLinks] = useState([]);
    const [usefulLinks, setUsefulLinks] = useState([]);
    const { setting } = useContext(SherpaContext);
    const [footerLinks, setFooterLinks] = useState([]);
    // const [error, setError] = useState('');
    useEffect(() => {
        //for assocatedwith and associated with
        axiosBase.get(`aboutus`)
            .then(response => response.data)
            .then((response) => setAboutus(response.data))
            .catch(err => {
                console.log(err.message);
            })
        //for explore listing
        axiosBase.get(`explorelinks`)
            .then(response => response.data)
            .then((response) => setExploreLinks(response.data))
            .catch(err => {
                console.log(err.message);
            })

    }, []);

    useEffect(() => {
        //for usefullinks except faq
        axiosBase.get(`useful-links`)
            .then(response => response.data)
            .then((response) => setUsefulLinks(response.data))
            .catch(err => {
                console.log(err.message);
            })

    }, []);
    useEffect(() => {
        //links for privacy, terms and cookies 
        axiosBase.get(`footer-links`)
            .then(response => response.data)
            .then((response) => setFooterLinks(response.data))
            .catch(err => {
                console.log(err.message);
            })

    }, []);

    // console.log(aboutus)
    return (
        <footer>
            <div className="container">
                <div className="row pt-1 pt-lg-3 pt-xl-4 pt-xxl-5">
                    <div className="col-12 col-md-8">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-4">
                                <h5 className="title">
                                    About Us
                                </h5>
                                <ul>
                                    <li ><Link to={`/contact-us`}>Contact Us</Link></li>
                                    <li ><Link to={`/about-us`}>About Us</Link></li>
                                    {/* <li ><Link to={`/blogs`}>Blogs</Link></li>
                                    <li ><Link to={`/careers`}>Careers</Link></li> */}
                                </ul>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <h5 className="title">
                                    Explore
                                </h5>
                                <ul>
                                    {explorelinks?.map((item) => (
                                        // <li key={item.id}><Link to={`${url}details/${item.slug}`} className='d-inline-block text-truncate w-100' style={{ textTransform: 'capitalize' }} >{item.title.toLowerCase()}</Link></li>
                                        <li key={item.id}><Link to={`/details/${item.slug}`} className='d-inline-block text-truncate w-100' style={{ textTransform: 'capitalize' }} >{item.title.toLowerCase()}</Link></li>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <h5 className="title">
                                    Useful Links
                                </h5>
                                <ul>
                                    <li><Link to={`/faq`}>FAQs</Link></li>
                                    {usefulLinks?.map((item) => (
                                        // <li key={item.id}><Link to={`${url}${item.slug}`}>{item.title}</Link></li>
                                        <li key={item.id}><Link to={`/${item.slug}`}>{item.title}</Link></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="row">
                            <div className="col-12">
                                <h5 className="title">
                                    {aboutus.heading4}
                                </h5>
                                <div className='associated-img row justify-content-evenly'>
                                    {aboutus.associatedwith?.map((item) => (
                                        <div className="col-3" key={item.id}>
                                            <Link to="/"><img src={item.img} alt="" /></Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-12">
                                <h5 className="title">
                                    {aboutus.heading5}
                                </h5>
                                {aboutus.weaccept?.map((item) => (
                                    <div className="we-accept" key={item.id}>
                                        <Link to="/"><img src={item.img} alt="" /></Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row footer-below">
                    {footerLinks?.map((item, i) => (
                        <Link key={i} className='w-auto' to={`/${item.slug}`}>{item.title}</Link>
                    ))}

                </div>
                <div className="row flex-column flex-sm-row justify-content-evenly footer-social">
                    <div className="col-12 col-sm-6 col-xl-4 d-flex">
                        <Link to={`mailto:${setting.email}`} className='text-decoration-underline px-3 px-sm-2'><i className="bi bi-envelope-open"></i></Link>
                        <span>{setting.email}</span>
                    </div>
                    <div className="col-12 col-sm-6 col-xl-4 d-inline-flex justify-content-between py-3 py-lg-0 footer-social-middle">
                        <Link to={`viber://chat?number=${setting.phone}`} className='px-2 position-relative' target="_blank">
                            <img src="/assets/img/viber.svg" alt="" className='' style={{ height: '16px', width: '16px' }} />
                            <div className="position-absolute bg-dark text-white p-1 rounded-2 footer-social-middle-tooltip" >
                                <span className='small text-nowrap'>Connect Viber</span>
                            </div>
                        </Link>
                        {setting.phone ? <Link to={`https://wa.me/${setting.phone.replace(" ", "")}`} className='px-2 position-relative' target="_blank">
                            <i className="bi bi-whatsapp"></i>
                            <div className="position-absolute bg-dark text-white p-1 rounded-2 footer-social-middle-tooltip" >
                                <span className='small text-nowrap'>Connect Whatsapp</span>
                            </div>
                        </Link> : ''}
                        <Link to={`tel:${setting.phone}`} className='px-2 position-relative'>
                            <i className="bi bi-telephone"></i>
                            <div className="position-absolute bg-dark text-white p-1 rounded-2 footer-social-middle-tooltip" >
                                <span className='small text-nowrap'>Call Us</span>
                            </div>
                        </Link>
                        <p className='px-2 text-nowrap mb-0'>{setting.phone}</p>
                        {/* +9779823117214 */}
                    </div>
                    <div className="col-12 col-md-6 col-xl-4 social-icon d-flex justify-content-between justify-content-lg-evenly">
                        <Link to={setting.twitter} target="_blank"><i className='bx bxl-trip-advisor'></i></Link>
                        <Link to={setting.facebook} target="_blank"><i className='bi bi-facebook'></i></Link>
                        <Link to={setting.github} target="_blank"><i className="bi bi-youtube"></i></Link>
                        <Link to={setting.instagram} target="_blank"><i className="bi bi-instagram"></i></Link>
                    </div>
                </div>

                <div className="col-12 text-center py-3">Copyright &copy; <span>{setting.website_title}</span>. All Rights Reserved.</div>
            </div>

        </footer >
    )
}
