import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick';

export default function LatestTrip(props) {
    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        cssEase: "linear",
        prevArrow: false,
        nextArrow: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };

    return (
        <div className='row latest-trip p-3 pb-0 p-md-5 pb-md-0 g-0'>
            <div className="col-12 col-md-6 latest-trip-left p-3">
                <div className='latest-trip-left-img'>
                    <img src={props.LatestTripImg} alt="" className='rounded-4' />
                    <Link to={props.LatestTripLink} className="latest-trip-left-img-overlay">
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <i className="bi bi-youtube fs-1"></i>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="col-12 col-md-6 latest-trip-right p-3 mb-md-4">
                <h2 className="text-uppercase fw-bold">latest trip review</h2>
                {/* {console.log(props.review)} */}
                <Slider {...settings}>
                    {props.review?.map((item, i) => (<div key={i}>
                        <h5 className="text-capitalize">{item.post_title}</h5>
                        <p className='textcolor-5 d-flex'>
                            {Array.from({ length: item.rating }, (_, i) => <i key={i} className="bi bi-star-fill"></i>)}
                        </p>
                        <p className='fst-italic'>{item.content}</p>

                        <p className="text-end mb-0"><i className="bi bi-person-circle px-2"></i> {item.name}</p>
                        {item.country != null ? <p className="text-end small">{item.country}</p> : ''}
                    </div>
                    ))}

                </Slider>
                {/* <p className='fst-italic'>{props.LatestTripMsg} Curabitur at justo faucibus, auctor erat sed, condimentum erat. Morbi et erat sed risus blandit sagittis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                    Nullam vestibulum orci quis nulla dignissim, id ullamcorper nibh luctus.</p> */}


            </div>
        </div>
    )
}
